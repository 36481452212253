import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { useTranslations } from "../../utils/intl"
import { BigButtonAnchor, H1, P, theme, toEm } from "../index"

const HeroBlock: React.FC<{
  elementRef: React.MutableRefObject<HTMLDivElement | null>
}> = ({ elementRef, ...props }) => {
  const {
    file: { childImageSharp: image },
  } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "iPhone-frame.png" }) {
        childImageSharp {
          gatsbyImageData(
            tracedSVGOptions: { threshold: 210 }
            placeholder: TRACED_SVG
            quality: 90
            breakpoints: [520, 800, 1000]
            sizes: "clamp(200px, 45vw, 975px)"
          )
        }
      }
    }
  `)

  const { it } = useTranslations()
  return (
    <section
      ref={elementRef}
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: `min(${(100 * 16) / 9}vw, 100vh)`,
        [`${BigButtonAnchor} + ${BigButtonAnchor}`]: {
          marginLeft: toEm(32, 24),
        },
      }}
      {...props}
    >
      <div
        css={{
          display: "grid",
          // backgroundColor: "rgba(0.5,0.5,0.5,0.05)",
          gridTemplateColumns: "5fr 1fr 5fr",
          maxWidth: toEm(1100),
          margin: "0 auto",
          paddingTop: toEm(32),
          paddingBottom: toEm(32),
        }}
      >
        <div
          css={{
            gridColumnStart: 1,
            gridColumnEnd: 3,
            gridRowStart: 2,
            gridRowEnd: 3,
            zIndex: 2,
          }}
        >
          <H1 css={{ letterSpacing: -2 }}>
            {it([
              "Turn your city into a fun game!",
              "Förvandla din stad till ett spel",
            ])}
          </H1>
          <P css={{ fontWeight: 500 }}>
            {it([
              "Walk, Jog, Run and explore more, your city turns into a fun game!",
              "Gå, jogga eller spring! Runbit förvandlar din stad till ett spel.",
            ])}
          </P>
          <div>
            <BigButtonAnchor
              href="https://itunes.apple.com/app/runbit-find-stars-walk-run/id940383157"
              variant="black"
            >
              iPhone
            </BigButtonAnchor>
            <BigButtonAnchor
              href="https://play.google.com/store/apps/details?id=se.soderstrom.runbit"
              color={theme.colors.green}
            >
              Android
            </BigButtonAnchor>
          </div>
        </div>
        <div
          css={{
            gridColumnEnd: 4,
            gridColumnStart: 2,
            gridRowStart: 1,
            gridRowEnd: 4,
            position: "relative",
            height: toEm(830),
          }}
        >
          <div
            css={{
              position: "absolute",
              left: toEm(100),
              height: toEm(830),
              width: toEm(480),
              transformOrigin: "50% 50%",
              transform: "rotate(20deg)",
            }}
          >
            <video
              autoPlay
              loop
              muted
              css={{
                position: "absolute",
                top: toEm(27),
                left: toEm(55),
                width: toEm(358),
              }}
            >
              <source
                src="https://d1yj9whwrn10mt.cloudfront.net/runbit/runbit_appstore_iphoneXS-2.mp4"
                type="video/mp4"
              />
            </video>
            <GatsbyImage
              alt="iPhone"
              image={getImage(image)!}
              css={{
                position: "absolute",
                width: toEm(650),
              }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroBlock
